import {cloneData, randomString} from "@/utils/form";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {EXCLUDED_ACTIVITIES} from "@/constants/booking/disclaimer.config";

const moment = require('moment');

export const CLUB_BOOKING_WITHOUT_DISCHARGE_VIEW_CREATE = "CLUB_BOOKING_WITHOUT_DISCHARGE_VIEW_CREATE";

export const isTrampolineParkHostname = () => true;

export const initProduct = (product, item, ticket) => {
  product.blockPriceId = item.id;
  product.blockPrice = item;
  product.blockPrice.maxParticipantsCountLimit = 1;
  product.name = item.name;
  product.quantity = 1;
  product.block = null;
  product.client = null;
  product.booking = null;
  product.price = null;
  product.options = [];
  product.playgrounds = null;
  product.slotDate = getCurrentDate();
  product.slots = [];
  product.participantsCount = null;
  product.participantCategory = null
  product.participants = [];
  product.isBlockPrice = true;
  product.ticket = ticket;
};

export const getCurrentDate = (date = null) => {
  let dateStart = date ? moment(date) : moment();

  dateStart.hours(0).minutes(0).second(0);

  return dateStart;
};

export const newDataProd = (id, name, amount, quantity) => {
  return {
    prodKey: 'prodid-' + randomString(),
    prodObject: {
      "id": id + '',
      "name": name,
      "price": amount + '',
      "quantity": quantity + '',
    }
  }
}

export const getGroupedParticipantsByCategories = (product, participantCategories) => {
  let participants = [];
  const timetableBlockPrice = product.booking.timetableBlockPrice.charAt(0) === '/' ? product.booking.timetableBlockPrice : '/' + product.booking.timetableBlockPrice;
  for (const participant of product.participants) {
    const participantCategory = participantCategories.find(el => el.category['@id'] === participant.category && timetableBlockPrice === el.blockPrice)

    if (isNotUndefinedAndNotNull(participantCategory)) {
      const findCreatedParticipantCategory = participants.find(el => el.category === participant.category);

      if (isNotUndefinedAndNotNull(findCreatedParticipantCategory)) {
        findCreatedParticipantCategory.quantity++;
        findCreatedParticipantCategory.participantCategories.push(participantCategory);
      } else {
        participants.push({
          category: participant.category,
          participantCategories: [participantCategory],
          name: participantCategory.category.label,
          nextoreProductReference: participantCategory.nextoreProductReference,
          price: hasAnEticket(product) ? 0 : participantCategory.pricePerParticipant / 100,
          quantity: 1,
        });
      }
    }
  }

  return participants;
}

export const hasAnEticket = (product) => {
  for (const participant of product.participants) {
    if (participant.clientEticket) {
      return true;
    }
  }

  return false;
}

export const isValidDisclaimer = (clientDisclaimerEnabled, client) => {
  if (!clientDisclaimerEnabled || !client) {
    return false;
  }

  const disclaimer = client.currentDisclaimer;

  if (!client.currentDisclaimer) {
    return true;
  }

  return moment(disclaimer.expiresAt).isBefore(moment());
}

export const isExcludedActivity = (activityId) => EXCLUDED_ACTIVITIES.includes(activityId);

export const onOptionAmountUpdate = (selectedOptions, data) => {
  const storedSelectedOptions = cloneData(selectedOptions);

  const parentIndex = storedSelectedOptions.findIndex(el => el.parentOption.id === data.parentOption.id);

  const parentOption = storedSelectedOptions[parentIndex];

  const targetIndex = parentOption.options.findIndex(el => el.id === data.dataOption.option.id);

  storedSelectedOptions[parentIndex].options[targetIndex].amount = data.dataOption.amount;

  return storedSelectedOptions;
}
export const onOptionSelect = (selectedOptions, data) => {
  const parentOption = selectedOptions.find(el => el.parentOption.id === data.parentOption.id);
  const clonedOption = cloneData(data.dataOption.optionItem.option);

  clonedOption.amount = data.dataOption.counter;

  if (parentOption) {
    const targetOption = parentOption.options.find(el => el.id === data.dataOption.optionItem.option.id);

    if (!isNotUndefinedAndNotNull(targetOption)) {
      parentOption.options.push(clonedOption);
    } else {
      parentOption.options.splice(parentOption.options.indexOf(targetOption), 1);
    }
  } else {
    const newParentOption = {
      amount: data.dataOption.counter,
      parentOption: data.parentOption,
      id: null,
      options: [clonedOption]
    }

    selectedOptions.push(newParentOption)
  }

  return selectedOptions;
}
export const onCounterUpdate = (selectedOptions, data) => {
  for (const selectedOption of selectedOptions) {
    if (selectedOption.parentOption.id === data.parentOption.id) {
      selectedOption.amount = data.counter;

      for (const option of selectedOption.options) {
        option.amount = data.counter;
      }
    }
  }

  return selectedOptions;
}